.slick-dots li button {
  text-indent: -50000px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #000;
  padding: 3px;
  transition: all 0.3s ease-in-out;
}

.slick-dots li {
  width: 8px;
}

.slick-dots li.slick-active button {
  background: #000;
  border-color: #000;
}
