.fancyBanner {
  background: #fcf7ea;
  padding: 75px 0 100px;
  position: relative;
  z-index: 1;
  margin-top: 150px;
}

.container {
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shapesShapeOne {
  top: 18%;
  left: 10%;
  position: absolute;
  z-index: -1;
  animation: jumpThree 4s infinite linear;
}

.shapesShapeTwo {
  top: 54%;
  right: 9%;
  position: absolute;
  z-index: -1;
  animation: jumpTwo 4s infinite linear;
}

@-webkit-keyframes jumpTwo {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40% {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes jumpTwo {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40% {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes jumpThree {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40% {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes jumpThree {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40% {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.title1 {
  text-align: center;
}

.title1 h2 {
  font-size: 38px;
  font-weight: 500;
  line-height: 1.32em;
  margin-bottom: 0px;
}

@media (min-width: 1024px) {
  .title1 h2 {
    font-size: 58px;
  }
}

.title1 h2 span {
  position: relative;
  display: inline-block;
}

.title1 h2 span::before {
  content: "";
  width: 100%;
  height: 13px;
  background: #ffeaa0;
  position: absolute;
  left: 0;
  bottom: 10px;
  z-index: -1;
}

.title2 {
  font-size: 22px;
  color: #000;
  padding-top: 22px;
  font-weight: 400;
  margin-bottom: 50px;
}
