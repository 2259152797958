@media (min-width: 768px) {
  .nav-item {
    padding: 30px 0;
  }
}

.en-language {
  color: #b7b7b7;
}

.en-language.active {
  color: black;
}

.en-language:hover {
  color: #3bb0d7 !important;
  cursor: pointer;
}

.vi-language {
  color: #b7b7b7;
}

.vi-language:hover {
  color: #3bb0d7 !important;
  cursor: pointer;
}
.vi-language.active {
  color: black;
}

.nav-item {
  margin: 0 30px;
  padding: 15px 0;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  vertical-align: middle;
}

.nav-item a {
  color: black;
}

.nav-item a:hover {
  color: #3bb0d7 !important;
  cursor: pointer;
}

.language {
  font-size: 16px;
  font-weight: 500;
  line-height: 46px;
}

.toeicx-header {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 30px 15px;
  transition: all 0.4s ease-out;
}

@media (min-width: 768px) {
  .toeicx-header {
    padding: 30px 25px;
  }
}

@media (min-width: 1024px) {
  .toeicx-header {
    padding: 30px 40px;
  }
}

.header-fixed {
  position: fixed;
  z-index: 9999;
  padding-top: 0px;
  padding-bottom: 0px;
  background: #fff;
  box-shadow: 0 13px 35px -12px rgb(35 35 35 / 10%);
}

.nav {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  background-color: white;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  transition: all 2s ease;
}

.hidden-nav {
  display: none;
}

.toogle-nav {
  margin: 10px;
  animation: all 20s linear infinite;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0.25rem 0.75rem;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (min-width: 1024px) {
  .nav {
    display: flex;
    flex-direction: row;
    position: static;
  }
  .toogle-nav {
    display: none;
  }
}
