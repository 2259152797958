.container {
  max-width: 1170px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
  }
}

.contactContainer {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .contactContainer {
    margin-top: 20px;
    display: flex;
    flex-basis: 50%;
  }
}

@media (min-width: 768px) {
  .logoContainer {
    flex-basis: 50%;
  }
}

.contactItem {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .contactItem {
    flex-basis: calc(100% / 2);
  }
}

.title {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 3px;
  padding-bottom: 15px;
  line-height: 1.2;
}

.link {
  font-weight: 400;
  font-size: 16px;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding: 35px 40px 50px;
  margin-top: 45px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}
