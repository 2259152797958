.checkoutModal {
  width: 1000px !important;
}

.button {
  cursor: pointer;
  width: 100%;
  text-align: center;
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 52px;
  border: 2px solid #000;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  background: #ffeaa0;
  border-color: #ffeaa0;
}

.buttonActive {
  background: #ffeaa0;
  border-color: #ffeaa0;
}

.title {
  font-size: 38px;
  margin-bottom: 0px;
  line-height: 1.1;
}

.subTitle {
  font-size: 20px;
  color: #73737b;
}

.wrapPaymentOptions {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

@media (min-width: 1024px) {
  .wrapPaymentOptions {
    flex-direction: row;
  }
}

.wrapPaymentOptionLeft {
  padding-bottom: 15px;
}

@media (min-width: 1024px) {
  .wrapPaymentOptionLeft {
    width: 50%;
    padding-right: 10px;
  }

  .wrapPaymentOptionRight {
    width: 50%;
    padding-left: 10px;
  }
}

.wrapPaymentOptionContent {
  background-color: #f1f4fb;
  border-radius: 20px;
  padding: 20px;
}

.titlePaymentOption {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: bold;
}

.wrapSignUp {
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #daf0ff;
  background-size: cover;
  border-radius: 20px;
  padding: 20px;
}

@media (min-width: 1024px) {
  .wrapSignUp {
    background: url(assets/shape/bg2.svg) no-repeat;
    background-size: cover;
    padding: 50px;
  }
}

.signUpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 1024px) {
  .signUpContainer {
    flex-direction: row;
  }
}

.signUpItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .signUpItem {
    flex-direction: column;
    align-items: flex-start;
  }
}

.downloadApp {
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .downloadApp {
    flex-direction: row;
  }
}

.signUpItem h6 {
  font-size: 20px;
  color: #f96f60;
  padding-bottom: 15px;
  font-family: Rubik, sans-serif !important;
  font-weight: 400;
}

.signUpItem h2 {
  font-size: 32px;
  line-height: 1.15em;
}

.chatBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  padding: 10px;
  background-color: #3697ff;
  color: white !important;
  font-size: 18px;
  border-radius: 10px;
}

.chatBtn:hover {
  background-color: #1184ff;
}

.chatBtnText {
  margin-left: 5px;
}
