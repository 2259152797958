.container {
  max-width: 540px;
  margin: 0px auto 0 auto;
  z-index: 1;
  padding: 100px 15px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1170px;
  }
}

.title1 {
  text-transform: uppercase;
  font-size: 16px;
  color: #adb1b5;
  letter-spacing: 1.12px;
  padding-bottom: 7px;
}

.title2 {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.2em;
  font-family: "Rubik", sans-serif !important;
  text-align: center;
  margin-bottom: 70px;
}

@media (min-width: 1024px) {
  .title2 {
    font-size: 58px;
    font-weight: 400;
    line-height: 1.2em;
    font-family: "Rubik", sans-serif !important;
    text-align: center;
    margin-bottom: 70px;
    max-width: 75%;
  }
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

@media (min-width: 1024px) {
  .sectionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}

.sectionBanner {
  margin-top: 50px;
}

.sectionContent {
  margin-top: 50px;
}

.sectionContentLeft {
  margin-top: 50px;
}

@media (min-width: 1024px) {
  .sectionContentLeft {
    margin-top: 50px;
    order: -1;
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
  }
  .sectionContent {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
  }
  .sectionBanner {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
  }
}

.titleH6 {
  font-size: 16px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 2px;
  margin-bottom: 0px;
}

.titleH2 {
  font-size: 28px;
  line-height: 1.28em;
  padding: 20px 0 20px;
  font-family: "Rubik", sans-serif !important;
  font-weight: 400;
  margin-bottom: 0px;
}

@media (min-width: 1024px) {
  .titleH2 {
    font-size: 42px;
    line-height: 1.28em;
    padding: 20px 0 20px;
    font-family: "Rubik", sans-serif !important;
    font-weight: 400;
    margin-bottom: 0px;
  }
}

.content {
  font-size: 18px;
  line-height: 1.59em;
  color: #73737b;
  margin-bottom: 0px;
}

.blockStyleTwelve {
  position: relative;
  padding-bottom: 100px;
}

.blockStyleTwelve::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 13px;
  left: 0;
  bottom: 25px;
  background: url(assets/shape/line-shape-9.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
