.container {
  max-width: 1170px;
  margin: 0px auto 0 auto;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title1 {
  text-transform: uppercase;
  font-size: 16px;
  color: #adb1b5;
  letter-spacing: 1.12px;
  padding-bottom: 7px;
}

.title2 {
  font-size: 40px;
  line-height: 1.25em;
  font-family: "Rubik", sans-serif !important;
  text-align: center;
  margin-bottom: 50px;
  font-weight: 400;
}

@media (min-width: 1024px) {
  .title2 {
    font-size: 54px;
    line-height: 1.25em;
    font-family: "Rubik", sans-serif !important;
    text-align: center;
    margin-bottom: 50px;
    font-weight: 400;
  }
}

.faqSection {
  background: #dff1ee;
  position: relative;
  z-index: 1;
  padding: 40px 15px 100px;
}

@media (min-width: 768px) {
  .faqSection {
    background: #dff1ee;
    position: relative;
    z-index: 1;
    padding: 40px 30px 100px;
  }
}

.faqSection::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100px;
  background: url(../../../assets/shape/118.svg) no-repeat center top;
  background-size: cover;
  left: 0;
  top: -98px;
}

.shapes {
  position: absolute;
  z-index: -1;
}

.shapeOne {
  left: 0;
  top: 21%;
}

.shapeTwo {
  right: 0;
  top: 75%;
}

.contentContainer {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1024px) {
  .contentContainer {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 75%;
  }
}

.card {
  background: #fff;
  border-radius: 0;
  border: none;
  margin-bottom: 10px;
  width: 100%;
}

.cardHeader {
  display: block;
  width: 100%;
  text-align: left;
  padding: 27px 35px 27px 15px;
  font-family: Rubik, sans-serif;
  font-size: 20px;
  margin: 0;
  position: relative;
}

@media (min-width: 1024px) {
  .cardHeader {
    display: block;
    width: 100%;
    text-align: left;
    padding: 27px 35px 27px 45px;
    font-family: Rubik, sans-serif;
    font-size: 24px;
    margin: 0;
    position: relative;
  }
}

.cardHeader:hover {
  color: #3bb0d7;
  cursor: pointer;
}

.cardHeader::before {
  content: "+";
  font-family: Rubik, sans-serif;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.cardBody {
  padding: 0 40px 20px 15px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 18px;
  position: relative;
  line-height: 1.7em;
  color: #73737b;
}

@media (min-width: 1024px) {
  .cardBody {
    padding: 0 50px 20px 45px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 18px;
    position: relative;
    line-height: 1.7em;
    color: #73737b;
  }
}

.contactHeader {
  font-size: 36px;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
  font-family: Rubik, sans-serif;
  line-height: 1.2;
}
