.container {
  padding-right: 15px;
  padding-left: 15px;
  max-width: 1170px;
  margin: 0px auto 0 auto;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title1 {
  margin-top: 20px;
  font-weight: 500;
  line-height: 1.32em;
  font-size: 38px;
  text-align: center;
}

@media (min-width: 1024px) {
  .title1 {
    margin-top: 20px;
    font-weight: 500;
    line-height: 1.32em;
    font-size: 64px;
    text-align: center;
  }
}

.title1 span {
  position: relative;
  display: inline-block;
}

.title1 span::before {
  content: "";
  width: 100%;
  height: 13px;
  background: #ffeaa0;
  position: absolute;
  left: 0;
  bottom: 10px;
  z-index: -1;
}

.title2 {
  font-weight: 400;
  font-size: 22px;
  color: #000;
  text-align: center;
}

.feedbackWrapper {
  border: 2px solid #e8edf0;
  border-radius: 10px;
  background: #fff;
  padding: 25px 15px 25px 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  margin: 80px 25px;
}

@media (min-width: 1024px) {
  .feedbackWrapper {
    border: 2px solid #e8edf0;
    border-radius: 10px;
    background: #fff;
    padding: 42px 44px 48px 57px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    margin: 80px 25px;
  }
}

.feedbackWrapper::before {
  content: url(../../../assets/icon/101.svg);
  position: absolute;
  bottom: 41px;
  right: 57px;
}

.feedbackWrapper p {
  font-size: 20px;
  line-height: 38px;
  color: #000;
  padding-bottom: 45px;
}

.feedbackWrapper img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 15px;
}

.feedbackWrapper h6 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0px;
}

.feedbackWrapper h6 span {
  font-weight: 400;
  font-size: 16px;
  color: #c1c1c1;
  margin-bottom: 0px;
}

.slick-center .feedbackWrapper {
  border-color: #fff;
  box-shadow: 0 35px 60px rgb(14 29 45 / 4%);
}

.feedBack .item {
  margin: 80px 25px;
}
