@-webkit-keyframes jumpTwo {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40% {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes jumpTwo {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40% {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes rotated {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotated {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.container {
  max-width: 1170px;
  margin: 0px auto;
  background: #fff6ec;
  z-index: 1;
  padding: 50px 0 190px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shapes {
  position: absolute;
  z-index: -1;
}

.shapeRight {
  right: -16px;
  top: 30%;
}

.shapeLeft {
  left: -8px;
  top: 62%;
}

.title1 {
  text-transform: uppercase;
  font-size: 16px;
  color: #adb1b5;
  letter-spacing: 1.12px;
  padding-bottom: 7px;
}

.title2 {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.2em;
  font-family: "Rubik", sans-serif !important;
  text-align: center;
  margin-bottom: 70px;
}

@media (min-width: 1024px) {
  .title2 {
    font-size: 50px;
    font-weight: 400;
    line-height: 1.2em;
    font-family: "Rubik", sans-serif !important;
    text-align: center;
    margin-bottom: 70px;
    max-width: 75%;
  }
}

.shapeOne {
  top: -20px;
  left: -64px;
  animation: jumpTwo 5s infinite linear;
}

.shapeTwo {
  bottom: -105px;
  right: -20px;
  animation: rotated 50s infinite linear;
}

.content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.contentItemContainer {
  display: flex;
  flex-basis: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 30px;
}

@media (min-width: 768px) {
  .contentItemContainer {
    display: flex;
    flex-basis: 50%;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 30px;
  }
}

@media (min-width: 1024px) {
  .contentItemContainer {
    display: flex;
    flex-basis: calc(100% / 3);
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 30px;
  }
}

.contentItem {
  position: relative;
  width: 100%;
  background: #fff;
  padding: 46px 30px 50px 48px;
  border-radius: 5px;
}

.title::before {
  content: "";
  width: 100%;
  height: 3px;
  background: #ffb840;
  border-radius: 2px;
  position: absolute;
  left: 0;
  bottom: -2px;
}

.title {
  font-size: 20px;
  margin: 24px 0 29px;
  display: inline-block;
  position: relative;
  font-family: "Rubik", sans-serif !important;
}

.contentDetail {
  font-size: 24px;
  line-height: 1.45em;
  color: #000;
}
