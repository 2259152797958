.container {
  max-width: 1170px;
  margin: 0px auto 0 auto;
  padding: 0 15px;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title1 {
  font-weight: 500;
  line-height: 1.32em;
  font-size: 38px;
  text-align: center;
}

@media (min-width: 1024px) {
  .title1 {
    font-size: 58px;
  }
}

.title1 span {
  position: relative;
  display: inline-block;
}

.title1 span::before {
  content: "";
  width: 100%;
  height: 13px;
  background: #ffeaa0;
  position: absolute;
  left: 0;
  bottom: 10px;
  z-index: -1;
}

.title2 {
  text-transform: uppercase;
  font-size: 16px;
  color: #adb1b5;
  letter-spacing: 1.12px;
  padding-bottom: 7px;
  margin-top: 50px;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.price {
  width: 100%;
  margin-bottom: 80px;
}

@media (min-width: 768px) {
  .priceContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 80px;
  }
  .price {
    width: 50%;
    padding: 20px;
    margin-bottom: 0px;
  }
}

@media (min-width: 1024px) {
  .priceContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 80px;
  }
  .price {
    width: calc(100% / 3);
    padding: 20px;
    margin-bottom: 0px;
  }
}

.feature {
  text-align: left;
  padding: 28px 0 42px 8px;
  width: 100%;
}

.feature p {
  position: relative;
  line-height: 42px;
  color: rgba(0, 0, 0, 0.7);
  padding-left: 30px;
  font-weight: 400;
  font-size: 18px;
}

.feature p::before {
  content: url(../../../assets/shape/76.svg);
  position: absolute;
  top: 0;
  left: 0;
}
