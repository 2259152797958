@import '~antd/dist/antd.css';

.logo {
  font-size: 35px;
  font-weight: 600;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #6f55ff;
  border-radius: 0;
}

::-webkit-scrollbar-track {
  background: #fff;
}

ul {
  padding-inline-start: 40px;
}

ol {
  padding-inline-start: 40px;
}
