.max-w-45 {
  max-width: 45rem;
}

.shapes {
  position: absolute;
  z-index: -1;
}

.hero-banner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 83%;
  background: url(assets/shape/67.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  left: 0;
  bottom: 78px;
  z-index: -1;
}

.hero-banner .illustration {
  margin: 95px auto 0;
}

.hero-banner .shape-one {
  top: 5%;
  right: 15%;
  animation: rotatedTwo 25s infinite linear;
}

.hero-banner .shape-two {
  top: 11%;
  right: 7%;
}

.hero-banner .shape-three {
  top: 24%;
  right: 20%;
}

.hero-banner .shape-four {
  top: 28%;
  right: 11%;
  animation: jumpTwo 5s infinite linear;
}

.hero-banner .shape-five {
  top: 40%;
  right: 8%;
}

.hero-banner .shape-six {
  top: 6%;
  left: 9%;
}

.hero-banner .shape-seven {
  top: 19%;
  left: 7%;
}

.hero-banner .shape-eight {
  top: 24%;
  left: 16%;
  animation: jumpThree 5s infinite linear;
}

.hero-banner .shape-nine {
  top: 35%;
  left: 21%;
}

.hero-banner .shape-ten {
  top: 42%;
  left: 11%;
  animation: rotatedTwo 50s infinite linear;
}
